@import "./../../utils/variables/media.scss";
@import "./../../utils/variables/colors.scss";

.containerSider {
  background: $primaryColor !important;
  height: 100vh !important;
  &_menu {
    background: $primaryColor !important;
    margin-top: 20px !important;
    &_item {
      background: $primaryColor !important;
      &_link {
        color: #fff !important;
      }
    }
    &_submenu {
      color: #fff !important;
      &_item {
        margin: 0 !important;
        background: $secondaryColor;
      }
    }
  }
  & * {
    color: #fff !important;
  }
}

.containerHeader {
  display: flex;
  justify-content: flex-end;
  margin-right: 50px;
  &_header {
    padding: 0 !important;
    background: $backgroundColor !important;
  }

  &_pageName {
    padding: 15px 10px;
    margin: 0;
    text-decoration: underline;
  }
}

.nameAdmin {
  color: #000;
  opacity: 0.6;

  &:hover {
    opacity: 1;
    color: #000 !important;
    transition: 1s ease-in;
  }
}
.mainContainer {
  margin: 0 auto;
}

.logout {
  display: block;
}

.dropdown {
  cursor: pointer;
}

.layout {
  display: flex;
  & * {
    color: rgba(0, 0, 0, 0.8);
    font-size: 16px;
  }
  &_sidebar {
    position: fixed;
    z-index: 1000;
    &_wrapper {
      position: relative;
    }
  }

  &_mainView {
    display: block;
    margin: 0 auto 0;
    width: 100%;
    background: $backgroundColor !important;
    &_content {
      margin: 0 auto;
      max-width: 1100px;
      width: 100%;
      background: $backgroundColor;
    }
  }

  &_btnUpWrapper {
    align-items: end;
    display: flex;
    position: fixed;
    right: 0;
    bottom: 0;

    &_btn {
      margin: 40px;
      border-radius: 10px;
    }
  }
}

h4 {
  margin: 0 !important;
}

.burgerMenu {
  display: flex;
  justify-content: space-between;
  padding: 10px 10px;
  align-items: center;
  &_btn {
    border: none;
    background-color: $backgroundColor;
    &_burgerIcon {
      width: 12px;
      height: 2px;
      background-color: black;
      margin: 2px 0;
    }
  }
  &:last-child {
    right: 0;
  }
}

:global(.DashboardContainer_container__2rP22) {
  display: block !important;
}

@media screen and (max-width: $medOneTh) {
  .layout_btnUpWrapper,
  .containerSider,
  .containerHeader {
    display: none;
  }
}

@media screen and (min-width: $medOneTh) {
  .burgerMenu {
    display: none;
  }
}

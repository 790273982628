.spin {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loadingOutlined {
  font-size: 24px;
}
